import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { 
        myFilledOrdersLoadedSelector, 
        myfilledOrdersSelector,
        myOpenOrdersLoadedSelector,
        myOpenOrdersSelector,
        exchangeSelector,
        accountSelector,
        orderCancellingSelector
    } from '../store/selectors';
import Spinner from './Spinner';
import { cancelOrder } from '../store/interactions';
import "./css/MyTransactions.css";

const showMyFilledOrders = (props) => {
    const { myFilledOrders } = props;
    
    return(
        <tbody className="noBorder">
            { 
                myFilledOrders.map((order) => {
                    return(
                        <tr key={order.id}>
                            <td className="text-muted noBorder">{ order.formattedTimestamp }</td>

                            <td className={`text-${order.orderTypeClass} noBorder`}>{ order.orderSign } { order.tokenAmount }    
                            </td>

                            <td className={`text-${order.orderTypeClass} noBorder`}>{ order.tokenPrice }
                            </td>
                        </tr>
                    )
                }) 
            }
        </tbody>
    )
}

const showMyOpenOrders = (props) => {
    const { myOpenOrders, dispatch, exchange, account } = props;

    return(
        <tbody className="noBorder">
        { 
            myOpenOrders.map((order) => {
                return (
                    <tr key={order.id}>
                        <td className={`text-${order.orderTypeClass} noBorder`}>{ order.tokenAmount }</td>
                        <td className={`text-${order.orderTypeClass} noBorder`}>{ order.tokenPrice }</td>
                        <td className="text-muted noBorder"
                        onClick={(event) => { 
                            cancelOrder(dispatch, exchange, order, account);    
                            }}>X</td>
                    </tr>
                )
            })
        }
        </tbody>
    )
}


class MyTransactions extends Component {

    render() {
        return(
            <div className="card myTransactionsCard text-white">
                <div className="card-header">
                    My Transactions
                </div>
                <div className="card-body">
                    <Tabs defaultActiveKey="trades" className="text-white">
                        <Tab eventKey="trades" title="Trades">
                            <table className="table text-white table-sm small transparentTable">
                                <thead>
                                    <tr>
                                        <th className="noBorder">TIME</th>
                                        <th className="noBorder">NER</th>
                                        <th className="noBorder">ETH/NER</th>
                                    </tr>
                                </thead>

                                { this.props.showMyFilledOrders ? showMyFilledOrders(this.props) : <Spinner type="table" />}
                            </table>
                        </Tab>

                        <Tab eventKey="orders" title="Orders">
                            <table className="table text-white table-sm small transparentTable">
                                <thead>
                                    <tr>
                                        <th className="noBorder">Amount</th>
                                        <th className="noBorder">ETH/NER</th>
                                        <th className="noBorder">Cancel</th>
                                    </tr>
                                </thead>

                                { this.props.showMyOpenOrders ? showMyOpenOrders(this.props) : <Spinner type="table" />}

                            </table>  
                        </Tab>
                    </Tabs>
                   
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const myOpenOrdersLoaded = myOpenOrdersLoadedSelector(state);
    const orderCancelling = orderCancellingSelector(state);

    return {
        myFilledOrders: myfilledOrdersSelector(state),
        showMyFilledOrders: myFilledOrdersLoadedSelector(state),
        myOpenOrders: myOpenOrdersSelector(state),
        showMyOpenOrders: myOpenOrdersLoaded && !orderCancelling,
        exchange: exchangeSelector(state),
        account: accountSelector(state)
    }
}

export default connect(mapStateToProps)(MyTransactions);