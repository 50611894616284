export function web3Loaded(connection) {
    return {
        type: 'WEB3_LOADED',
        connection
    }
}

export function web3AccountLoaded(account) {
    return {
        type: 'WEB3_ACCOUNT_LOADED',
        account
    }
}

export function tokenLoaded(contract) {
    return {
        type: 'TOKEN_LOADED',
        contract
    }
}

export function exchangeLoaded(contract) {
    return {
        type: 'EXCHANGE_LOADED',
        contract
    }
}

export function cancelledOrdersLoaded(cancelledOrders) {
    return {
        type: 'CANCELLED_ORDERS_LOADED',
        cancelledOrders
    }
}

export function filledOrdersLoaded(filledOrders) {
    return {
        type: 'FILLED_ORDERS_LOADED',
        filledOrders
    }
}

export function allOrdersLoaded(allOrders) {
    return {
        type: 'ALL_ORDERS_LOADED',
        allOrders
    }
}

export function orderCancelling(){
    return{
        type: 'ORDER_CANCELLING'   
    }
}

export function orderCancelled(order) { 
    return { 
        type: 'ORDER_CANCELLED',
        order
    }
}


export function orderFilling(account) {
    return {
        type: 'ORDER_FILLING',
        account
    }
}

export function orderFilled(order) {
    return {
        type: 'ORDER_FILLED',
        order
    }
}

export function etherBalanceLoaded(balance) { 
    return {
        type: 'ETHER_BALANCE_LOADED',
        balance
    }
}

export function tokenBalanceLoaded(balance) {
    return {
        type: 'TOKEN_BALANCE_LOADED',
        balance
    }
}

export function exchangeEtherBalanceLoaded(balance) {
    return {
        type: 'EXCHANGE_ETHER_BALANCE_LOADED',
        balance
    }
}

export function exchangeTokenBalanceLoaded(balance) {
    return {
        type: 'EXCHANGE_TOKEN_BALANCE_LOADED',
        balance
    }
}

export function balancesLoaded() {
    return {
        type: 'BALANCES_LOADED'
    }
}

export function balancesLoading() {
    return {
        type: 'BALANCES_LOADING'
    }
}

export function etherDepositAmountChanged(amount) {
    return {
        type: 'ETHER_DEPOSIT_AMOUNT_CHANGED', 
        amount
    }
} 

export function etherWithdrawAmountChanged(amount) {
    return {
        type: 'ETHER_WITHDRAW_AMOUNT_CHANGED',
        amount
    }
}

export function tokenDepositAmountChanged(amount) {
    return {
        type: 'TOKEN_DEPOSIT_AMOUNT_CHANGED', 
        amount
    }
} 

export function tokenWithdrawAmountChanged(amount) {
    return {
        type: 'TOKEN_WITHDRAW_AMOUNT_CHANGED',
        amount
    }
}

export function buyOrderAmountChanged(amount) {
    return {
        type: 'BUY_ORDER_AMOUNT_CHANGED',
        amount
    }
}

export function buyOrderPriceChanged(price) {
    return {
        type: 'BUY_ORDER_PRICE_CHANGED',
        price
    }
}

export function buyOrderMaking() {
    return {
        type: 'BUY_ORDER_MAKING',
    }
}

// NOTE: both buy and sell will trigger the generic 'orderMade'
export function orderMade(order) {
    return {
        type: 'ORDER_MADE',
        order
    }
}

export function sellOrderAmountChanged(amount) {
    return {
        type: 'SELL_ORDER_AMOUNT_CHANGED',
        amount
    }
}

export function sellOrderPriceChanged(price) {
    return {
        type: 'SELL_ORDER_PRICE_CHANGED',
        price
    }
}

export function sellOrderMaking() {
    return {
        type: 'SELL_ORDER_MAKING',
    }
}

export function balanceViewAssetsTabChanged(balanceViewAssetTab) {
    return {
        type: "BALANCE_VIEW_ASSETS_TAB_CHANGED",
        balanceViewAssetTab 
    }
}

export function balanceViewAssetActionsTabChanged(assetActionTabs) {
    return {
        type: "BALANCE_VIEW_ASSET_ACTION_TAB_CHANGED",
        assetActionTabs
    }
}

export function newOrderViewActionTabChanged(actionTabs) {
    return {
        type: "NEW_ORDER_VIEW_ACTION_TAB_CHANGED",
        actionTabs
    }
}

export function updatedMyNewFilledOrder(myNewestFilledOrder, exchangeEtherBalance, exchangeTokenBalance) {
    return {
        type: "UPDATED_MY_NEW_FILLED_ORDER",
        myNewestFilledOrder,
        exchangeEtherBalance,
        exchangeTokenBalance 
    }
}