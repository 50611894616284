import React, { Component } from 'react';
import './App.css';
import Token from '../abis/Token.json';
import { loadWeb3, loadAccount, loadToken, loadExchange } from '../store/interactions';
import { connect } from 'react-redux';
import { accountSelector } from '../store/selectors';
import Navbar from './Navbar';
import Content from './Content';
import { contractsLoadedSelector } from '../store/selectors';

class App extends Component {

  componentWillMount() {
    this.loadBlockchainData(this.props.dispatch);
  }

  async loadBlockchainData(dispatch) { 
    const web3 = await loadWeb3(dispatch);
    await loadAccount(web3, dispatch);
    await web3.eth.net.getNetworkType();
    const networkId = await web3.eth.net.getId();
    // Information needed for instantiating a smart contract is:
    // token.abi and token address
    const token = await loadToken(web3, networkId, dispatch);
    if(!token) {
      window.alert('Token smart contract not detected on the current network. Please select another network with Metamask.');
      return;
    }
    const exchange = loadExchange(web3, networkId, dispatch);
    if(!exchange) {
      window.alert('Exchange smart contract not detected on the current netowrk. Pleaseselect another network with Metamask.');
      return;
    }

  }

  render() {
    return (
      <div>
        <Navbar />
        { this.props.contractsLoaded ? <Content/> : <div className="content"></div> }
      </div> 
    );
  }
}

function mapStateToProp(state){
  return {
    contractsLoaded: contractsLoadedSelector(state)
  }
}

export default connect(mapStateToProp)(App);
