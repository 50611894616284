export const ETHER_ADDRESS = '0x0000000000000000000000000000000000000000';
export const DECIMALS = (10 ** 18);
export const GREEN = 'newGreen';
export const RED = 'newRed';

// Converts Wei into ether:
export const ether = (wei) => {
    if(wei){
        return(wei / DECIMALS);
    }
}

// Same as the ether:
export const tokens = ether;

export const formatBalance = (balance) => {
    const precision = 100;

    balance = ether(balance);

    // Rounding to 2 decimal places:
    balance = Math.round(balance * precision) / precision;
    
    return balance;
}