import React, { Component } from 'react';
import { connect } from "react-redux";
import Chart from 'react-apexcharts';
import Spinner from './Spinner';
import { chartOptions, dummyData } from "./PriceChart.config";
import { 
    priceChartLoadedSelector,
    priceChartSelector
} from '../store/selectors';
import "./css/PriceChart.css";

const priceSymbol = (lastPriceChange) => {
    let output;
    console.log("LAstPriceChange is: ", lastPriceChange);
    if(lastPriceChange === '+'){
        // Green Up Traingle:
        output = <span className="text-success">&#9650;</span>
    }else{
        // Red Down Triangle:
        output = <span className="text-danger">&#9660;</span>
    }
    
    return(output);
}

const showPriceChart = (priceChart) => {
    console.log("Price chart value in showPriceChart is: ", priceChart);
    return(
        <div className="price-chart">
            <div className = "price transperantOpacityBackground">
                <h2>ETH/NER &nbsp; { priceSymbol(priceChart.lastPriceChange) } &nbsp; { priceChart.lastPrice }</h2>
                {/* { priceChart.lastPrice != null ? <div><span>Last Price: {priceChart.lastPrice } </span></div> : <div></div> } */}
            </div>

            <Chart options={chartOptions} series={priceChart.series} type='candlestick' width='100%' height='100%'/>
        </div>
    )
}

class PriceChart extends Component {
    render() {
        return(
            <div className="card priceChartCard text-white">
                <div className="card-header">Price Chart</div>
            
                <div className="card-body">{ this.props.priceChartLoaded ? showPriceChart(this.props.priceChart) : <Spinner /> }</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        priceChartLoaded: priceChartLoadedSelector(state),
        priceChart: priceChartSelector(state)
    }
}

export default connect(mapStateToProps)(PriceChart)