import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    exchangeSelector,
    tokenSelector,
    accountSelector,
    web3Selector,
    buyOrderSelector,
    sellOrderSelector,
    newOrderViewActionTabSelector
} from '../store/selectors';
import { 
    makeBuyOrder,
    makeSellOrder
} from '../store/interactions';
import Spinner from './Spinner';
import { 
    buyOrderAmountChanged,
    buyOrderPriceChanged,
    sellOrderAmountChanged,
    sellOrderPriceChanged,
    newOrderViewActionTabChanged
} from '../store/actions';
import "./css/NewOrder.css";




class NewOrder extends Component {
    showBuyForm = true;
    

    toggleFormToShow = (tab, e) => {
        e.preventDefault();
        const { dispatch } = this.props;


        if(tab == null) { 
            return;
        } else if(tab.name === 'Buy') {
            this.showBuyForm = true;
        } else if(tab.name === 'Sell'){
            this.showBuyForm = false; 
        }

        tab.current = true;

        this.props.tabs.forEach(t => {
            if(t.name === tab.name){
                t.current = tab.current;
            }else{ 
                t.current = false;
            }
        });


        this.setState(prevState => ({ ...prevState}));

        this.resetNewOrderFormData(dispatch);

        // dispatching action:
        this.props.dispatch(newOrderViewActionTabChanged(this.props.tabs));
    }

    resetNewOrderFormData = (dispatch) => {
        var buyFormElement = document.getElementById("newOrderViewBuyForm");
        var sellFormElement = document.getElementById("newOrderViewSellForm");

        if(buyFormElement != null) {
            buyFormElement.reset();
            dispatch(buyOrderAmountChanged(0));
        }

        if(sellFormElement != null) {
            sellFormElement.reset();
            dispatch(sellOrderAmountChanged(0));
        }


    }
    
    showForm = (props) => {
        const {
            dispatch,
            tabs
        } = props
    
        
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }
    
        return(
            <div className='flex flex-col justify-center rounded-md px-4 pt-4'>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        defaultValue={tabs != null ? tabs.find((tab) => tab.current).name: ""}
                        onChange={e => this.toggleFormToShow(tabs.find((tab) => tab.current), e)}
                    >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="transperantOpacityBackground flex flex-row space-x-4" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                            key={tab.name}
                            onClick={e => this.toggleFormToShow(tab, e)}
                            className={classNames(
                                tab.current ? `${tab.name}-background text-black` : 'text-white-500 hover:text-white-700',
                                'px-3 py-2 font-medium text-sm rounded-md w-1/2 text-center'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </a>
                        ))}
                    </nav>
                </div>


                { this.showBuyForm ? this.buyForm(props) : this.sellForm(props)}
            </div>
        )
    }

    buyForm = (props) => {

        const { 
            dispatch,
            buyOrder,
            exchange,
            token,
            web3,
            account,
            showBuyTotal
        } = props
        return(
            <form id="newOrderViewBuyForm" className='pt-4' onSubmit={(event) => {
                event.preventDefault();
                if(account != null){
                    makeBuyOrder(dispatch, exchange, token, web3, buyOrder, account);
                }else{
                    window.alert("Please sign with Metamask to make a buy order");
                }
            }}>
                <div className='form-group small pt-2'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium">
                            Amount 
                        </label>
                        <div className="mt-1 relative flex items-center">
                            <input
                                type="text"
                                name="search"
                                className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                onChange={ (e) => dispatch(buyOrderAmountChanged(e.target.value))}
                                placeholder="0.00"
                                required
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                NER
                            </kbd>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='form-group small pt-2'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium">
                            Price
                        </label>
                        <div className="mt-1 relative flex items-center">
                            <input
                                type="text"
                                name="search"
                                className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                placeholder="0.00"
                                onChange={ (e) => dispatch(buyOrderPriceChanged(e.target.value))}
                                required
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                ETH
                            </kbd>
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="pt-2 buttonMaxWidth">
                    <button 
                        type="submit"
                        className="btn btn-primary btn-sm btn-block newGreen w-full text-black font-medium text-sm"
                    >
                        Place Buy Order
                    </button>

                    { showBuyTotal ? <small>Total: {buyOrder.amount * buyOrder.price} ETH</small>: null }
                </div>
            </form>
        );
    }

    sellForm = (props) => {
        const {
            dispatch,
            exchange,
            token,
            web3,
            account,
            sellOrder,
            showSellTotal,
        } = props

        
        return(
            <form id="newOrderViewSellForm" className='pt-4' onSubmit={(event) => {
                    event.preventDefault();

                    if(account != null){
                        makeSellOrder(dispatch, exchange, token, web3, sellOrder, account);
                    }else{
                        window.alert("Please sign with Metamask to make a buy order");
                    }
                }}>
                    <div className='form-group small pt-2'>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium">
                                Amount
                            </label>
                            <div className="mt-1 relative flex items-center">
                                <input
                                    type="text"
                                    name="search"
                                    className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                    onChange={ (e) => dispatch(sellOrderAmountChanged(e.target.value))}
                                    placeholder="0.00"
                                    required
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                    NER
                                </kbd>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group small pt-2'>

                        <div>
                            <label htmlFor="name" className="block text-sm font-medium">
                                Price
                            </label>
                            <div className="mt-1 relative flex items-center">
                                <input
                                    type="text"
                                    name="search"
                                    className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                    placeholder="0.00"
                                    onChange={ (e) => dispatch(sellOrderPriceChanged(e.target.value))}
                                    required
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                    ETH
                                </kbd>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-2 buttonMaxWidth">
                        <button 
                            type="submit"
                            className="btn btn-primary btn-sm btn-block newRed w-full text-black font-medium text-sm"
                        >
                        Place Sell Order
                        </button>

                        { showSellTotal ? <small>Total: {sellOrder.amount * sellOrder.price} ETH</small>: null }
                    </div>
            </form>  
        );
    }

    render() {
        return(
            <div className="card newOrderCard text-white">
                <div className="card-header">
                    New Order
                </div>
                <div className="card-body">
                    { this.props.showForm ? this.showForm(this.props) :<Spinner /> }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {  
    const buyOrder = buyOrderSelector(state);
    const sellOrder = sellOrderSelector(state);


    return {
        account: accountSelector(state),
        exchange: exchangeSelector(state),
        token: tokenSelector(state),
        web3: web3Selector(state),
        buyOrder,
        sellOrder,
        showForm: !buyOrder.making && !sellOrder.making,
        showBuyTotal: buyOrder.amount && buyOrder.price,
        showSellTotal: sellOrder.amount && sellOrder.price,
        tabs: newOrderViewActionTabSelector(state) 
    }
  }

export default connect(mapStateToProps)(NewOrder);