import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fillOrder } from '../store/interactions';
import { 
    orderBookSelector, 
    orderBookLoadedSelector,
    orderFillingSelector,
    accountSelector,
    exchangeSelector 
} from '../store/selectors';
import Spinner from './Spinner';
import "./css/OrderBook.css";

class OrderBook extends Component {

    renderOrder = (order, props) => {
        const { dispatch, exchange, account } = props;
    
        return(
            <OverlayTrigger
            key={order.id}
            placement='auto'
            overlay={
                <Tooltip id={order.id}>
                    {`Click here to ${order.orderFillAction}`}
                </Tooltip>
            }>
    
                <tr key={order.id}
                    className="order-book-order"
                    onClick={(event) => { 
                            if(order.user !== account) {
                                if(account != null) { 
                                    // Saving the previous state:
                                    this.setState(prevState => ({ ...prevState}));
                                    fillOrder(dispatch, exchange , order, account)
                                } else {
                                    window.alert("Please sign in with Metamask to fullfill order");
                                }
                            }else{
                                window.alert("You can't fullfill your own order. Please chooses another order.");
                            }
                        }
                    }>
    
                    <td className="noBorder">{order.tokenAmount}</td>
                    <td className={`text-${order.orderTypeClass} noBorder`}>{order.tokenPrice}</td>
                    <td className="noBorder">{order.etherAmount}</td>
                </tr>
    
            </OverlayTrigger>
        )
    }
    
    showOrderBook = (props) => {
        const { orderBook } = props;
    
        return(
            <tbody className="noBorder">
                { orderBook.sellOrders.map((order) =>  this.renderOrder(order, props) )}
                <tr className="card-header">
                    <th className="noBorder">NER</th>
                    <th className="noBorder">ETH/NER</th>
                    <th className="noBorder">ETH</th>
                </tr>
                { orderBook.buyOrders.map((order) => this.renderOrder(order, props) )}
            </tbody>
        )
    }


    render() {
        return (
            <div className="vertical">
                <div className="card orderBookCard text-white">
                <div className="card-header">
                    Order Book
                </div>
                <div className="card-body order-book">
                    <table className="table text-white table-sm small transparentTable">
                        { this.props.showOrderBook ? this.showOrderBook(this.props) : <Spinner type='table'/> }
                    </table>
                </div>
            </div>
        </div>
        );
    }
}

function mapStateToProps(state) { 
    const orderBookLoaded = orderBookLoadedSelector(state)
    const orderFilling = orderFillingSelector(state)
    return {
        orderBook: orderBookSelector(state),
        showOrderBook: orderBookLoaded && !orderFilling,
        account: accountSelector(state),
        exchange: exchangeSelector(state) 
    }
}

export default connect(mapStateToProps)(OrderBook);