/* 
* This file is going to handle the blockchain interactions 
*/
import Web3 from 'web3';
import { 
    web3Loaded,
    web3AccountLoaded,
    tokenLoaded,
    exchangeLoaded, 
    cancelledOrdersLoaded,
    filledOrdersLoaded,
    allOrdersLoaded,
    orderCancelling,
    orderCancelled,
    orderFilling,
    orderFilled,
    etherBalanceLoaded,
    tokenBalanceLoaded,
    exchangeEtherBalanceLoaded,
    exchangeTokenBalanceLoaded,
    balancesLoaded,
    balancesLoading,  
    orderMade,
    buyOrderMaking,
    sellOrderMaking,
    updatedMyNewFilledOrder
    } from "./actions";
import Token from '../abis/Token.json';
import Exchange from '../abis/Exchange.json';
import { ETHER_ADDRESS, ether, tokens } from '../helpers';

export const loadWeb3 = async (dispatch) => {
    if(typeof window.ethereum!=='undefined'){
        const web3 = new Web3(window.ethereum);
        dispatch(web3Loaded(web3));
        return web3;
    } else {
        window.alert("Please install Metamask");
        window.location.assign("https://metamask.io/");

    }

}

export const loadAccount = async (web3, dispatch) => {
    const accounts = await web3.eth.getAccounts();
    const account = await accounts[0];
    if(typeof account !== 'undefined'){
      dispatch(web3AccountLoaded(account));
      return account;
    } else {
      
      // Removing alert and showing the data: 
      // window.alert('Please login with MetaMask');
      return null;
    }
}

export const loadToken = async (web3, networkId, dispatch) => {
    try {
      const token = new web3.eth.Contract(Token.abi, Token.networks[networkId].address);
      dispatch(tokenLoaded(token));
      return token;
    } catch (error) {
      console.log('Contract not deployed to the current network. Please select another network with Metamask.');
      return null;
    }
  }

export const loadExchange = async (web3, networkId, dispatch) => {
    try {
      const exchange = new web3.eth.Contract(Exchange.abi, Exchange.networks[networkId].address);
      dispatch(exchangeLoaded(exchange));
      return exchange;
    } catch (error) {
      console.log('Contract not deployed to the current network. Please select another network with Metamask.');
      return null;
    }
}

export const loadAllOrders = async (exchange, dispatch) => {
  // Fetch cancelled orders with the "Cancel" event:
  // NOTE: this is going to be looking at the entire blockchain
  const cancelStream = await exchange.getPastEvents('Cancel', { fromBlock: 0, toBlock: 'latest'});
  
  const cancelledOrders = cancelStream.map((event) => event.returnValues);

  // Addding the cancelled orders to the redux store:
  dispatch(cancelledOrdersLoaded(cancelledOrders));

  // Fetch filled order with the "Trade" event:
  // NOTE: this is going to be looking at the entire blockchain
  const tradeStream = await exchange.getPastEvents('Trade', { fromBlock: 0, toBlock: 'latest'});
  
  const filledOrders = tradeStream.map((event) => event.returnValues);

  // Addding the traded orders to the redux store:
  dispatch(filledOrdersLoaded(filledOrders));



  // Fetch all orders with the "Order" Event
  // NOTE: this is going to be looking at the entire blockchain
  const orderStream = await exchange.getPastEvents('Order', { fromBlock: 0, toBlock: 'latest'});
  
  const allOrders = orderStream.map((event) => event.returnValues);

  // Addding the all orders to the redux store:
  dispatch(allOrdersLoaded(allOrders));
}

export const cancelOrder = (dispatch, exchange , order, account) => {
  // call the cancel action in the smart contract:
  // NOTE: Going to use an event emitter: 
  exchange.methods.cancelOrder(order.id).send({ from: account })
  .on('transactionHash', (hash) => {
    // doing a redux action:
    dispatch(orderCancelling())
  })
  .on('error', (error) => {
    console.log(error)
    window.alert('There was an error!')
  })
}

export const fillOrder = (dispatch, exchange , order, account) => {
  // call the fill action in the smart contract:
  // NOTE: Going to use an event emitter: 
  exchange.methods.fillOrder(order.id).send({ from: account })
  .on('transactionHash', (hash) => {
    // doing a redux action:
    dispatch(orderFilling(account));
    debugger;
  })
  .on('error', (error) => {
    console.log(error)
    window.alert('There was an error!')
  })
}

export const subscribeToEvents = async(exchange, dispatch) => { 
  // Listening to the Cancel event in the smart contract:
  exchange.events.Cancel({}, (error, event) => {
    dispatch(orderCancelled(event.returnValues))
  });

  // Listening to the Trade event in the smart contract:
  exchange.events.Trade({}, (error, event) => {
    // debugger;
    console.log("exchange.events.Trade: ");
    console.log(event.returnValues);

    dispatch(orderFilled(event.returnValues));
  });

  // Listening to a Deposit event:
  exchange.events.Deposit({}, (error, event) => {
    dispatch(balancesLoaded())
  });

  // Listening to a Withdraw event:
  exchange.events.Withdraw({}, (error, event) => {
    dispatch(balancesLoaded())
  });

  // Listening to the order made event: 
  exchange.events.Order({}, (error, event) => {
    dispatch(orderMade(event.returnValues));
  })
}

export const loadBalances = async (dispatch, web3, exchange, token, account) => {
  // Checking that the account is hooked up using metamask:
  if(typeof account !== 'undefined') {
  
    const etherBalnces = await loadEtherBalances(dispatch, web3, exchange, account);

    const tokenBalances = await loadTokenBalances(dispatch, exchange, token, account);

    // show all the balances loaded on Redux: 
    dispatch(balancesLoaded());

  } else {
    // Removing alert and showing the data: 
    // window.alert('Please login with Metamask');

    // dispatch the balances loaded:
    dispatch(balancesLoaded());

  }
}

const loadEtherBalances = async (dispatch, web3, exchange, account) => {
  if(typeof account !== 'undefined') {
    // Fetch the ether balance from the metamask wallet:
    const etherBalance = await web3.eth.getBalance(account);
    dispatch(etherBalanceLoaded(etherBalance));

    // Fetch Ether balance in the exchange:
    const etherExchangeBalance = await exchange.methods.balanceOf(ETHER_ADDRESS, account).call();
    dispatch(exchangeEtherBalanceLoaded(etherExchangeBalance));
    
  }

  return;
}

const loadTokenBalances = async (dispatch, exchange, token, account) => {
  
  if(typeof account !== 'undefined') {
    // Fetching the token balance from the metamask wallet:
    const tokenBalance = await token.methods.balanceOf(account).call();
    dispatch(tokenBalanceLoaded(tokenBalance));

    // Fetch the token balance in the exchange:
    const tokenExchangeBalance  = await exchange.methods.balanceOf(token.options.address, account).call()
    dispatch(exchangeTokenBalanceLoaded(tokenExchangeBalance));

  }

  return;
}

export const updateExchangeTokenAndEtherForUI = (dispatch, web3, exchangeEtherBalance, exchangeTokenBalance, newFilledOrder) => {
  var orderType;
  var exchangeEtherBalanceAsFloat;
  var exchangeTokenBalanceAsFloat;
  var amountGet;
  var amountGive;

  if (newFilledOrder !== null) {
    // dispatch(balancesLoading());

    orderType = newFilledOrder.tokenGive === ETHER_ADDRESS ? 'sell' : 'buy';

    if(orderType === "sell") {
        // Selling NER Token:
        // Decrease NER Exchange
        // Increase ETH Exchange
        amountGet = tokens(newFilledOrder.amountGet);
        amountGive = ether(newFilledOrder.amountGive);

        exchangeTokenBalanceAsFloat = parseFloat(exchangeTokenBalance) - parseFloat(amountGet);
        exchangeEtherBalanceAsFloat = parseFloat(exchangeEtherBalance) + parseFloat(amountGive);

        exchangeEtherBalance = web3.utils.toWei(exchangeEtherBalanceAsFloat.toString(), 'ether');
        exchangeTokenBalance = web3.utils.toWei(exchangeTokenBalanceAsFloat.toString(), 'ether');

        
        dispatch(updatedMyNewFilledOrder(null, exchangeEtherBalance, exchangeTokenBalance));

        // dispatch(exchangeTokenBalanceLoaded(exchangeTokenBalance));
        // dispatch(exchangeEtherBalanceLoaded(exchangeEtherBalance)); 


        // dispatch(balancesLoaded());

    } else if (orderType === "buy") { 
        // Buying NER token:
        // Increase NER Exchange
        // Decrease ETH Exchange
        amountGet = ether(newFilledOrder.amountGive);
        amountGive = tokens(newFilledOrder.amountGet);

        exchangeTokenBalanceAsFloat = parseFloat(exchangeTokenBalance) + parseFloat(amountGet);
        exchangeEtherBalanceAsFloat = parseFloat(exchangeEtherBalance) - parseFloat(amountGive);
        exchangeEtherBalance = web3.utils.toWei(exchangeEtherBalanceAsFloat.toString(), 'ether');
        exchangeTokenBalance = web3.utils.toWei(exchangeTokenBalanceAsFloat.toString(), 'ether');

        dispatch(updatedMyNewFilledOrder(null, exchangeEtherBalance, exchangeTokenBalance));

        // dispatch(exchangeTokenBalanceLoaded(exchangeTokenBalance));
        // dispatch(exchangeEtherBalanceLoaded(exchangeEtherBalance)); 

        // dispatch(balancesLoaded());
    }
    
  }

}

export const depositEther = (dispatch, exchange, web3, amount, account, exchangeEtherBalance, etherBalance) => {
  var etherBalanceAsFloat;
  var exchangeEtherBalanceAsFloat;

  dispatch(balancesLoading());

  exchange.methods.depositEther().send({ from: account, value: web3.utils.toWei(amount, 'ether')})
  .on('transactionHash', (hash) => {

    etherBalanceAsFloat = parseFloat(etherBalance) - parseFloat(amount);
    exchangeEtherBalanceAsFloat = parseFloat(exchangeEtherBalance) + parseFloat(amount);

    etherBalance = web3.utils.toWei(etherBalanceAsFloat.toString(), 'ether');
    exchangeEtherBalance = web3.utils.toWei(exchangeEtherBalanceAsFloat.toString(), 'ether');
 

    dispatch(etherBalanceLoaded(etherBalance));
    dispatch(exchangeEtherBalanceLoaded(exchangeEtherBalance));

    dispatch(balancesLoaded());

  })
  .on('error', (error) =>{
    dispatch(balancesLoaded());
    console.error(error);
    window.alert("There was an error!")
  })
}

export const withdrawEther = (dispatch, exchange, web3, amount, account, exchangeEtherBalance, etherBalance) => {
  var etherBalanceAsFloat;
  var exchangeEtherBalanceAsFloat;

  dispatch(balancesLoading());

  exchange.methods.withdrawEther(web3.utils.toWei(amount, 'ether')).send({ from: account })
  .on('transactionHash', (hash) => {
    etherBalanceAsFloat = parseFloat(etherBalance) + parseFloat(amount);
    exchangeEtherBalanceAsFloat = parseFloat(exchangeEtherBalance) - parseFloat(amount);

    etherBalance = web3.utils.toWei(etherBalanceAsFloat.toString(), 'ether');
    exchangeEtherBalance = web3.utils.toWei(exchangeEtherBalanceAsFloat.toString(), 'ether');
 

    dispatch(etherBalanceLoaded(etherBalance));
    dispatch(exchangeEtherBalanceLoaded(exchangeEtherBalance));

    dispatch(balancesLoaded());

  })
  .on('error', (error) =>{
    dispatch(balancesLoaded());

    console.error(error);
    window.alert("There was an error!")
  })
}

// NOTE: Depositing tokens is a 2-step process.
// It must be approved before it can be deposited
export const depositToken = (dispatch, exchange, web3, token, amount, account, exchangeTokenBalance, tokenBalance) => {
  var tokenBalanceAsFloat;
  var exchangeTokenBalanceAsFloat;

  var amountInWeiFormat = web3.utils.toWei(amount, 'ether')

  dispatch(balancesLoading());
  
  token.methods.approve(exchange.options.address, amountInWeiFormat).send({ from: account })
  .on('transactionHash', (hash) => {
      debugger;
      exchange.methods.depositToken(token.options.address, amountInWeiFormat).send({ from: account })
      .on('transactionHash', (hash) => {

        tokenBalanceAsFloat = parseFloat(tokenBalance) - parseFloat(amount);
        exchangeTokenBalanceAsFloat = parseFloat(exchangeTokenBalance) + parseFloat(amount);

        tokenBalance = web3.utils.toWei(tokenBalanceAsFloat.toString(), 'ether');
        exchangeTokenBalance = web3.utils.toWei(exchangeTokenBalanceAsFloat.toString(), 'ether');
        debugger;
        dispatch(tokenBalanceLoaded(tokenBalance));
        dispatch(exchangeTokenBalanceLoaded(exchangeTokenBalance));

        dispatch(balancesLoaded());

      })
      .on('error',(error) => {
        dispatch(balancesLoaded());
        console.log("Error when depositing tokens")
        console.error(error)
        window.alert(`There was an error!`)
      })
  })
  .on("error", (error) =>{
      dispatch(balancesLoaded());

      console.log("Error in approving the token");
      console.log(error);
  })
}

export const withdrawToken = (dispatch, exchange, web3, token, amount, account, exchangeTokenBalance, tokenBalance) => {
  var tokenBalanceAsFloat;
  var exchangeTokenBalanceAsFloat;
  
  dispatch(balancesLoading())

  exchange.methods.withdrawToken(token.options.address, web3.utils.toWei(amount, 'ether')).send({ from: account })
  .on('transactionHash', (hash) => {

    tokenBalanceAsFloat = parseFloat(tokenBalance) + parseFloat(amount);
    exchangeTokenBalanceAsFloat = parseFloat(exchangeTokenBalance) - parseFloat(amount);

    tokenBalance = web3.utils.toWei(tokenBalanceAsFloat.toString(), 'ether');
    exchangeTokenBalance = web3.utils.toWei(exchangeTokenBalanceAsFloat.toString(), 'ether');

    dispatch(tokenBalanceLoaded(tokenBalance));
    dispatch(exchangeTokenBalanceLoaded(exchangeTokenBalance));

    dispatch(balancesLoaded());

  })
  .on('error', (error) => {
    dispatch(balancesLoaded());
    console.error(error);
    window.alert("There was an error in withdrawToken")
  })
}

export const makeBuyOrder = (dispatch, exchange, token, web3, order, account) => {
  const tokenGet = token.options.address;
  const amountGet = web3.utils.toWei(order.amount, 'ether');
  const tokenGive = ETHER_ADDRESS;
  const amountGive = web3.utils.toWei((order.amount * order.price).toString(), 'ether');

  exchange.methods.makeOrder(tokenGet, amountGet, tokenGive, amountGive).send({ from: account })
  .on('transactionHash', (hash) => {
    dispatch(buyOrderMaking());
  })
  .on('error', (error) => {
    console.log("Error in makeBuyOrder:");
    console.log(error);
    console.log("There was an error!");
  })
}

export const makeSellOrder = (dispatch, exchange, token, web3, order, account) => {
  const tokenGet = ETHER_ADDRESS;
  const amountGet = web3.utils.toWei((order.amount * order.price).toString(), 'ether');
  const tokenGive = token.options.address;
  const amountGive = web3.utils.toWei(order.amount, 'ether');

  exchange.methods.makeOrder(tokenGet, amountGet, tokenGive, amountGive).send({ from: account })
  .on('transactionHash', (hash) => {
    dispatch(sellOrderMaking());
  })
  .on('error', (error) => {
    console.log("Error in makeSellOrder:");
    console.log(error);
    console.log("There was an error!");
  })
}