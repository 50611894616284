import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    web3Selector, 
    exchangeSelector,
    tokenSelector,
    accountSelector,
    etherBalanceSelector,
    tokenBalanceSelector,
    exchangeEtherBalanceSelector,
    exchangeTokenBalanceSelector,
    balancesLoadingSelector,
    etherDepositAmountSelector,
    etherWithdrawAmountSelector,
    tokenDepositAmountSelector,
    tokenWithdrawAmountSelector,
    balanceViewAssetTabSelector,
    balanceViewAssetActionTabSelector,
    myNewFilledOrderSelector
} from '../store/selectors';
import { 
    loadBalances,
    depositEther,
    withdrawEther,
    withdrawToken,
    depositToken,
    updateExchangeTokenAndEtherForUI
} from '../store/interactions';
import Spinner from './Spinner';
import { 
    etherDepositAmountChanged, 
    etherWithdrawAmountChanged,
    tokenDepositAmountChanged,
    tokenWithdrawAmountChanged,
    balanceViewAssetsTabChanged,
    balanceViewAssetActionsTabChanged
    } from '../store/actions';
import "./css/Balance.css";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Balance extends Component {
    componentWillMount() {
        this.loadBlockchainData()
    }

    async loadBlockchainData() {
        const { dispatch, web3, exchange, token, account } = this.props
        await loadBalances(dispatch, web3, exchange, token, account);
    }

    showAsset = (props) => {
        const { dispatch, assets, updateExchangeValues } = props;
        var assetData;
        
        if(updateExchangeValues) {
            this.updateExchangeValuesIfNeeded(props);
        }

        assets.forEach((asset) => {
            if(asset.current && asset.name === "ETH") {
                assetData = this.getEthData(props);
            } else if(asset.current && asset.name === "NER") {
                assetData = this.getNerdyTokenData(props);
            }

        })  

        console.log("Assets in the showAssets function: ", assets);

        return(
            <div>
                <div className='flex flex-row justify-between rounded-md px-4 pt-4'>
                    <label>Asset</label>                

                    <div className='flex flex-col justify-center '>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                                id="assets"
                                name="assets"
                                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                defaultValue={assets != null ? assets.find((asset) => asset.current).name : ""}
                                onChange={e => this.toggleAssetDataToShow(dispatch, assets.find((asset) => asset.current), e)}
                            >
                                {assets.map((asset) => (
                                    <option key={asset.name}>{asset.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="flex flex-row space-x-4" aria-label="Tabs">
                                {assets.map((asset) => (
                                    <a
                                    key={asset.name}
                                    onClick={e => this.toggleAssetDataToShow(dispatch, asset, e)}
                                    className={classNames(
                                        asset.current ? `${asset.name}-background border` : 'hover:text-white-700',
                                        'px-3 py-2 font-medium text-sm rounded-md w-1/2 text-center text-white-500 transperantOpacityBackground'
                                    )}
                                    aria-current={asset.current ? 'page' : undefined}
                                    >
                                        {asset.name}
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                
                <div className="px-4 pt-4 text-center">
                    { assetData }
                </div>
                
                { this.showForm(props) }

            </div>
        )
    }
    getEthData = (props) => {

        const { 
            etherBalance,
            exchangeEtherBalance,
            } = props

        return (
            <table className="table text-white table-sm small transperantOpacityBackground">
                <thead>
                    <tr>
                        <th className="noBorder">Token</th>
                        <th className="noBorder">Wallet</th>
                        <th className="noBorder">Exchange</th>
                    </tr>
                </thead>

                <tbody className="noBorder">
                    <tr>
                        <td className="noBorder">ETH</td>
                        <td className="noBorder">{ etherBalance }</td>
                        <td className="noBorder">{ exchangeEtherBalance }</td>
                    </tr>
                </tbody>
            </table>
        )
    }
    
    getNerdyTokenData = (props) => {
        const { 
            tokenBalance,
            exchangeTokenBalance,
        } = props

        return(
            <table className="table text-white table-sm small transperantOpacityBackground">
                <thead>
                    <tr>
                        <th className="noBorder">Token</th>
                        <th className="noBorder">Wallet</th>
                        <th className="noBorder">Exchange</th>
                    </tr>
                </thead>
                <tbody className="noBorder">
                    <tr>
                        <td className="noBorder">NER</td>
                        <td className="noBorder">{ tokenBalance }</td>
                        <td className="noBorder">{ exchangeTokenBalance }</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    toggleAssetDataToShow = (dispatch, assetTab, e) => {
        e.preventDefault();
        if(assetTab == null) {
            return;
        }

        assetTab.current = true;

        this.props.assets.forEach(aTab => {
            if(aTab.name === assetTab.name) {
                aTab.current = assetTab.current;
            } else {
                aTab.current = false;
            }
        });

        this.resetFormInputData();
        
        this.setState(prevState => ({ ...prevState}));

        // dispatching action:
        this.props.dispatch(balanceViewAssetsTabChanged(this.props.assets))

    }
    
    showForm = (props) => {
        const {
            dispatch,
            exchange,
            web3,
            tabs
          } = props;
          var currentTab;
          var formData;
          
          console.log("SHOW FORM Above tabs.forEach()");

          tabs.forEach(tab => {
            if(tab.current){
                currentTab = tab;
            }
          });
          
          console.log("currentTab in showForm:", currentTab);
          console.log("Tabs value are: ", tabs);


          if(currentTab.name === "Deposit") { 
            formData = this.getDepositForm(props);
          } else if(currentTab.name === "Withdraw") { 
            formData = this.getWithdrawForm(props);
          }
        return(
            <div className="balanceFormWrapper">
                <div className='flex flex-col justify-center rounded-md px-4 pt-4'>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            defaultValue={tabs != null ? tabs.find((tab) => tab.current).name: ""}
                            onChange={e => this.toggleFormToShow(dispatch, tabs, tabs.find((tab) => tab.current), e)}
                        >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="transperantOpacityBackground flex flex-row space-x-4" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                key={tab.name}
                                onClick={e => this.toggleFormToShow(dispatch, tabs, tab, e)}
                                className={classNames(
                                    tab.current ? `${tab.name}-background text-white-700 border` : 'text-white-500 hover:text-white-700',
                                    'px-3 py-2 font-medium text-sm rounded-md w-1/2 text-center rounded-md'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>

                    <div className="balanceFormWrapper">
                    { formData }
                </div>

                </div>
                
            </div>
        )
    }

    toggleFormToShow = (dispatch, tabs, tab, e) => {
        e.preventDefault();
        console.log("toggleFormToShow SHOWING TEST FORM");
        console.log("Tab value is: ", tab);
        if(tab == null) {
            console.log('TAB IS NULL') 
            return;
        }

        tab.current = true;

        tabs.forEach(t => {
            if(t.name === tab.name){
                t.current = tab.current;
            }else{ 
                t.current = false;
            }
        });


        this.setState(prevState => ({ ...prevState}))


        console.log("Tabs sent to dispatch: ", tabs);
       
        this.resetFormInputData();
        dispatch(balanceViewAssetActionsTabChanged(tabs));
    }

    // Need to make this more generic for both ETH AND NER:
    getDepositForm = (props) => {
        const {
            dispatch,
            exchange,
            web3,
            account,
            etherDepositAmount,
            etherBalance,
            tokenBalance,
            exchangeEtherBalance,
            exchangeTokenBalance, 
            token,
            tokenDepositAmount,
            assets,
            
          } = props
          var currentAsset;

          assets.forEach(asset => {
              if(asset.current){
                  currentAsset = asset;
              }
          });
   

        return (
            <div>
                <form id="balanceViewDepositForm" className="row" onSubmit={(event) => {
                    event.preventDefault();
                    var currentAsset;

                    if(account != null) {
                        assets.forEach(asset => {
                            if(asset.current){
                                currentAsset = asset;
                            }
                        });
                        
                        if(currentAsset.name === "ETH") {
                            console.log("Depositing ETHER")

                            depositEther(dispatch, exchange, web3, etherDepositAmount, account, exchangeEtherBalance, etherBalance);

                        } else if (currentAsset.name === "NER") {
                            console.log("Depositing NER");
                            
                            depositToken(dispatch, exchange, web3, token, tokenDepositAmount, account, exchangeTokenBalance, tokenBalance);

                        }
                    } else {
                        window.alert("Please sign in with Metamask to deposit")
                    }
                }}>

                    <div className='form-group small pt-2'>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium">
                                Amount
                            </label>
                            <div className="mt-1 relative flex items-center">
                                <input
                                    type="text"
                                    className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                    onChange={ (e) => {
                                        if(currentAsset.name === "ETH"){
                                            console.log("Dispatching on ETH")
                                            this.setState(prevState => ({ ...prevState}))
    
                                            
                                            dispatch(etherDepositAmountChanged(e.target.value))
                                            console.log("Assets: ", this.props.assets);
                                        } else if (currentAsset.name === "NER") {
                                            console.log("Dispatching on NER token")
                                            this.setState(prevState => ({ ...prevState}));
    
                                            
                                            dispatch(tokenDepositAmountChanged(e.target.value));
                                            console.log("Assets: ", this.props.assets);
    
                                        }
                                    }}
                                    placeholder="0.00"
                                    required
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                   {currentAsset.name} 
                                </kbd>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-auto pl-sm-0 pt-2 buttonMaxWidth">
                        <button type="submit" className="btn btn-primary btn-block btn-sm transperantOpacityBackground border w-full">
                            Confirm Deposit
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    // Need to make this more generic for both ETH AND NER:
    getWithdrawForm = (props) => {
        const {
            dispatch,
            exchange,
            web3,
            account,
            etherBalance,
            tokenBalance,
            exchangeEtherBalance,
            exchangeTokenBalance,
            token,
            etherWithdrawAmount,
            tokenWithdrawAmount,
            assets,
            tabs
          } = props
        var currentAsset;

        assets.forEach(asset => {
            if(asset.current){
                currentAsset = asset;
            }
        });

        return (
           <div>
                <form id="balanceViewWithdrawForm" className="row" onSubmit={(event) => {
                    event.preventDefault();
                    
                    if(account != null){
                        if(currentAsset.name === "ETH") {
                            console.log("Withdrawing ETHER")

                            withdrawEther(dispatch, exchange, web3, etherWithdrawAmount, account, exchangeEtherBalance, etherBalance);

                        } else if (currentAsset.name === "NER") {
                            console.log("Withdrawing NER");
                            
                            withdrawToken(dispatch, exchange, web3, token, tokenWithdrawAmount, account, exchangeTokenBalance, tokenBalance);

                        }
                    } else {
                        window.alert("Please sign in with Metamask to withdraw")
                    }
                }}>

                    <div className='form-group small pt-2'>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium">
                                Amount
                            </label>
                            <div className="mt-1 relative flex items-center">
                                <input
                                    type="text"
                                    className="transperantOpacityBackground block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                    onChange={ (e) => {
                                        if(currentAsset.name === "ETH"){
                                            console.log("Dispatching on ETH")
                                            this.setState(prevState => ({ ...prevState}))
    
                                            
                                            dispatch(etherWithdrawAmountChanged(e.target.value))
                                            console.log("Assets: ", this.props.assets);
                                        } else if (currentAsset.name === "NER") {
                                            console.log("Dispatching on NER token")
                                            this.setState(prevState => ({ ...prevState}));
    
                                            
                                            dispatch(tokenWithdrawAmountChanged(e.target.value));
                                            console.log("Assets: ", this.props.assets);
    
                                        }
                                    }}
                                    placeholder="0.00"
                                    required
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="floatingTextBackground inline-flex items-center rounded px-2 text-sm font-sans font-medium text-white-400">
                                   {currentAsset.name} 
                                </kbd>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-auto pl-sm-0 pt-2 buttonMaxWidth">
                        <button type="submit" className="btn btn-primary btn-block btn-sm transperantOpacityBackground border w-full">
                            Confirm Withdraw
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    
    resetFormInputData = () => {
        var depositFormElement = document.getElementById("balanceViewDepositForm");
        var withdrawFormElement = document.getElementById("balanceViewWithdrawForm");

        if(depositFormElement != null) {
            depositFormElement.reset();
        }

        if(withdrawFormElement != null) {
            withdrawFormElement.reset();
        }

    }

    updateExchangeValuesIfNeeded = (props) => {
        const { 
            dispatch,
            web3,
            exchangeEtherBalance, 
            exchangeTokenBalance, 
            myNewFilledOrder, 
            } = props;

        updateExchangeTokenAndEtherForUI(dispatch, web3, exchangeEtherBalance, exchangeTokenBalance, myNewFilledOrder);
        
    }

    render() {
        return(
            <div className="card balanceCard text-white">
                <div className="card-header">
                    Balance
                </div>

                <div className="card-body">
                    { this.props.showForm ? this.showAsset(this.props) : <Spinner/>}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const balancesLoading = balancesLoadingSelector(state);
    var newFilledOrder = myNewFilledOrderSelector(state)

    console.log("newFilledOrder in the Balance View");
    console.log(newFilledOrder);
    
    if(newFilledOrder != null){
        console.log("Nice, not null");
        debugger;
    }
    return {
        account: accountSelector(state),
        exchange: exchangeSelector(state),
        token: tokenSelector(state),
        web3: web3Selector(state),
        etherBalance: etherBalanceSelector(state),
        tokenBalance: tokenBalanceSelector(state),
        exchangeEtherBalance: exchangeEtherBalanceSelector(state),
        exchangeTokenBalance: exchangeTokenBalanceSelector(state),
        balancesLoading,
        showForm: !balancesLoading,
        etherDepositAmount: etherDepositAmountSelector(state),
        etherWithdrawAmount: etherWithdrawAmountSelector(state),
        tokenDepositAmount: tokenDepositAmountSelector(state),
        tokenWithdrawAmount: tokenWithdrawAmountSelector(state),
        assets: balanceViewAssetTabSelector(state),
        tabs: balanceViewAssetActionTabSelector(state),
        myNewFilledOrder: newFilledOrder,
        updateExchangeValues: newFilledOrder != null

    }
  }

export default connect(mapStateToProps)(Balance);