import { get, reject, groupBy, maxBy, minBy } from 'lodash';
import { createSelector } from "reselect";
import { ETHER_ADDRESS, tokens, ether, GREEN, RED, formatBalance } from '../helpers';
import moment from 'moment';


// get is going to be used here to prevent a crash
// if web3 doesn't exist
const account = state => get(state, 'web3.account');
export const accountSelector = createSelector(account, a => a);

const web3 = state => get(state, 'web3.connection');
export const web3Selector = createSelector(web3, w => w);

// Token laoaded default is false:
const tokenLoaded = state => get(state, 'token.loaded', false);
export const tokenLoadedSelector = createSelector(tokenLoaded, tl => tl);

const token = state => get(state, 'token.contract');
export const tokenSelector = createSelector(token, t => t);

const exchangeLoaded = state => get(state, 'exchange.loaded', false);
export const exchangeLoadedSelector = createSelector(exchangeLoaded, el => el);

const exchange = state => get(state, 'exchange.contract');
export const exchangeSelector = createSelector(exchange, e => e);

export const contractsLoadedSelector = createSelector(tokenLoaded, exchangeLoaded,( tl, el) => (tl && el));

const allOrdersLoaded = state => get(state, 'exchange.allOrders.loaded', false);
const allOrders = state => get(state, 'exchange.allOrders.data', []);


const cancelledOrdersLoaded = state => get(state, 'exchange.cancelledOrders.loaded', false);
export const cancelledOrdersLoadedSelector = createSelector(cancelledOrdersLoaded, loaded => loaded);
const cancelledOrders = state => get(state, 'exchange.cancelledOrders.data', []);
export const cancelledOrdersSelector = createSelector(cancelledOrders, cOrders => cOrders);


const filledOrdersLoaded = state => get(state, 'exchange.filledOrders.loaded', false);
export const filledOrdersLoadedSelector = createSelector(filledOrdersLoaded, loaded => loaded);
const filledOrders = state => get(state, 'exchange.filledOrders.data', []);
export const filledOrdersSelector = createSelector(filledOrders, 
                                    (orders) => {
                                        // Ascending date order for display:
                                        orders = orders.sort((x, y) => x.timestamp - y.timestamp);

                                        // Decorating the orders:
                                        orders = decorateFilledOrders(orders);
                                        
                                        // Descending date order sort for display:
                                        orders = orders.sort((x, y) => y.timestamp - x.timestamp);
                                        
                                        return orders;
                                    });

                                
const mostRecentFilledOrder = state => get(state, 'exchange.mostRecentFilledOrder', null);
export const mostRecentFilledOrderSelector = createSelector(mostRecentFilledOrder, recentFilledOrder => recentFilledOrder);


const decorateFilledOrders = (orders) => {
    let previousOrder = orders[0];

    return (
        orders.map((order) => {
            order = decorateOrder(order);
            order = decorateFilledOrder(order, previousOrder);
            previousOrder = order;
            return order;
        })
    )
}

const decorateOrder = (order) => {
    let etherAmount;
    let tokenAmount;
    let tokenPrice;
    const precision = 100000;

    // using address 0x as ether: 
    if(order.tokenGive === ETHER_ADDRESS) {
        // NOTE: Trading ether for token:
        etherAmount = order.amountGive;
        tokenAmount = order.amountGet;
    } else {
        // NOTE: Trading token for ether:
        etherAmount = order.amountGet;
        tokenAmount = order.amountGive;
    }

    // Calculating the token price: 
    tokenPrice = (etherAmount / tokenAmount);
    tokenPrice = Math.round(tokenPrice * precision) / precision;

    return ({
        ...order,
        etherAmount: ether(etherAmount),
        tokenAmount: tokens(tokenAmount),
        tokenPrice, 
        formattedTimestamp: moment.unix(order.timestamp).format('h:mm::ss a M/D'),
    });
}

const decorateFilledOrder = (order, previousOrder) => {
    return({
        ...order,
        tokenPriceClass: tokenPriceClass(order.tokenPrice, order.id, previousOrder)
    })
}


const tokenPriceClass = (tokenPrice, orderId, previousOrder) => {
    // Green if current order higher than previous order
    // Red if current order lower that previous order
    if (previousOrder.id === orderId){
        return GREEN;
    }else if(previousOrder.tokenPrice <= tokenPrice) {
        return GREEN;
    }else{
        return RED;
    }
}

const openOrders = state => {
    const all = allOrders(state);
    const cancelled = cancelledOrders(state);
    const filled = filledOrders(state);

    // openOrders aßre neither filled or cancelled:
    const openOrders = reject(all, (order) => {
        const orderFilled = filled.some((o) => o.id === order.id);
        const orderCancelled = cancelled.some((o) => o.id === order.id);
        return(orderFilled || orderCancelled);
    });

    return openOrders;
}

const orderBookLoaded = state => cancelledOrdersLoaded(state) && filledOrdersLoaded(state) && allOrdersLoaded(state);
export const orderBookLoadedSelector = createSelector(orderBookLoaded, loaded => loaded )

export const orderBookSelector = createSelector(
    openOrders,
    (orders) => {
        orders = decorateOrderBookOrders(orders);

        orders = groupBy(orders, 'orderType');

        const buyOrders = get(orders, 'buy', []);

        orders = {
            ...orders,
            buyOrders: buyOrders.sort((x, y) => y.tokenPrice - x.tokenPrice)
        }

        const sellOrders = get(orders, 'sell',[]);

        orders = {
            ...orders,
            sellOrders: sellOrders.sort((x, y) => y.tokenPrice - x.tokenPrice)
        }

        return orders;
    }
);


const decorateOrderBookOrders = (orders) => {
    return (orders.map((order) => {
        order = decorateOrder(order);
        order = decorateOrderBookOrder(order);
        return(order);
    }));
}

const decorateOrderBookOrder = (order) => {
    const orderType = order.tokenGive === ETHER_ADDRESS ? 'buy': 'sell';
    return ({
        ...order,
        orderType,
        orderTypeClass: (orderType === 'buy' ? GREEN : RED),
        orderFillAction: orderType === 'buy' ? 'sell' : 'buy'
    });
}

export const myFilledOrdersLoadedSelector = createSelector(filledOrdersLoaded, loaded => loaded);

export const myfilledOrdersSelector = createSelector(account, filledOrders, (account, orders) => {
    // Finding the orders:
    orders = orders.filter((o) => o.user === account || o.userFill === account);
    
    // Sorting ascending by date: 
    orders = orders.sort((a, b) => a.timestamp - b.timestamp);

    orders = decorateMyFilledOrders(orders, account);
    
    return orders;
});

export const myNewFilledOrderSelector = createSelector(account, mostRecentFilledOrder, (account, mostRecentFilledOrder) => {
    // current uses filled the order: 
    if (account != null && mostRecentFilledOrder != null){ 
        if(account === mostRecentFilledOrder.userFill){
            return mostRecentFilledOrder;
            // updateMyExchangeValues(mostRecentFilledOrder) 
        }
    }

    return null;
    
    // console.log("newFilledOrder");
    // debugger;
});

const updateMyExchangeValues = (newFilledOrder, exchangeEtherBalance, exchangeTokenBalance) => {
    let orderType;

    orderType = newFilledOrder.tokenGive === ETHER_ADDRESS ? 'sell' : 'buy';

    if(orderType === "sell") {
        // Selling NER Token
        // Decrease NER Exchange
        // Increase ETH Exchange


    } else if (orderType === "buy") { 
        // Buying NER token 
        // Increase NER Exchange
        // Decrease ETH Exchange
        // newFilledOrder.amountGive + 

    }
}

const decorateMyFilledOrders = (orders, account) => {
    return(
        orders.map((order) => {
        order = decorateOrder(order)
        order = decorateMyFilledOrder(order, account)
        return(order)
        })
    )
}


export const decorateMyFilledOrder = (order, account) => {
    const myOrder = order.user === account;
    let orderType;

    if(myOrder) {
        orderType = order.tokenGive === ETHER_ADDRESS ? 'buy' : 'sell';
    } else { 
        orderType = order.tokenGive === ETHER_ADDRESS ? 'sell' : 'buy';
    }

    return({
        ...order,
        orderType, 
        orderTypeClass: (orderType === 'buy' ? GREEN : RED),
        orderSign: (orderType === 'buy' ? '+' : '-')
    })
}

export const myOpenOrdersLoadedSelector = createSelector(orderBookLoaded, loaded => loaded);

export const myOpenOrdersSelector = createSelector(account, openOrders, (account, orders) => {
    orders = orders.filter((order) => order.user === account);

    orders = decorateMyOpenOrders(orders);

    // Sorting my descending date:
    orders = orders.sort((x, y) => y.timestamp - x.timestamp);

    return orders;
});

const decorateMyOpenOrders = (orders, account) => {
    return(
        orders.map((order) => {
            order = decorateOrder(order);
            order = decorateMyOpenOrder(order, account);
            return(order);
        })
    );
}

const decorateMyOpenOrder = (order, account) => {
    let orderType = order.tokenGive === ETHER_ADDRESS ? 'buy' : 'sell';

    return({
        ...order,
        orderType,
        orderTypeClass: (orderType === 'buy' ? GREEN : RED)
    })
}


export const priceChartLoadedSelector = createSelector(filledOrdersLoaded, loaded => loaded);

export const priceChartSelector = createSelector(filledOrders,
    (orders) => {
        orders = orders.sort((x, y) => x.timestamp - y.timestamp);
        orders = orders.map((or) => decorateOrder(or));

        // Getting last 2 orders for final & price change:
        let secondLastOrder;
        let lastOrder;
        [secondLastOrder, lastOrder] = orders.slice(orders.length - 2, orders.length);
        
        const lastPrice = get(lastOrder, 'tokenPrice', 0);
        const secondLastPrice = get(secondLastOrder, 'tokenPrice', 0);
        
        return({
            lastPrice,
            lastPriceChange: (lastPrice >= secondLastPrice ? '+' : '-'),
            series: [{
                data: buildGraphData(orders)
            }]
        })
    });

const buildGraphData = (orders) => {
    orders = groupBy(orders, (or)=> moment.unix(or.timestamp).startOf('hour').format());
    const hours = Object.keys(orders);
    const graphData = hours.map((hour) => {
        const group = orders[hour];
        // Calculating Open, High, Low and Close:
        const open = group[0];
        const high = maxBy(group, 'tokenPrice');
        const low = minBy(group, 'tokenPrice');
        const close = group[group.length - 1];

        return({
            x: new Date(hour),
            y: [open.tokenPrice, high.tokenPrice, low.tokenPrice, close.tokenPrice]
        })
    })
    return graphData;
}

const orderCancelling = state => get(state, 'exchange.orderCancelling', false);
export const orderCancellingSelector = createSelector(orderCancelling, status => status);


const orderFilling = state => get(state, 'exchange.orderFilling', false);
export const orderFillingSelector = createSelector(orderFilling, status => status);


// Balances: 
const balancesLoading = state => get(state, 'exchange.balancesLoading', true);
export const balancesLoadingSelector = createSelector(balancesLoading, status => status);

const etherBalance = state => get(state, 'web3.balance', 0);
export const etherBalanceSelector = createSelector(
    etherBalance,
    (balance) => {
        return formatBalance(balance)
    });

const tokenBalance = state => get(state, 'token.balance', 0);
export const tokenBalanceSelector = createSelector(
    tokenBalance,
    (balance) => {
        return formatBalance(balance)
    });

const exchangeEtherBalance = state => get(state, 'exchange.etherBalance', 0);
export const exchangeEtherBalanceSelector = createSelector(
    exchangeEtherBalance,
    (balance) => {
        return formatBalance(balance)
    });

const exchangeTokenBalance = state => get(state, 'exchange.tokenBalance', 0);
export const exchangeTokenBalanceSelector = createSelector(
exchangeTokenBalance,
(balance) => {
    return formatBalance(balance)
});

const etherDepositAmount = state => get(state, 'exchange.etherDepositAmount', null);
export const etherDepositAmountSelector = createSelector(etherDepositAmount, amount => amount);

const etherWithdrawAmount = state => get(state, 'exchange.etherWithdrawAmount', null);
export const etherWithdrawAmountSelector = createSelector(etherWithdrawAmount, amount => amount);


const tokenDepositAmount = state => get(state, 'exchange.tokenDepositAmount', null);
export const tokenDepositAmountSelector = createSelector(tokenDepositAmount, amount => amount);

const tokenWithdrawAmount = state => get(state, 'exchange.tokenWithdrawAmount', null);
export const tokenWithdrawAmountSelector = createSelector(tokenWithdrawAmount, amount => amount);

const buyOrder = state => get(state, 'exchange.buyOrder', {});
export const buyOrderSelector = createSelector(buyOrder, order => order);

const sellOrder = state => get(state, 'exchange.sellOrder', {});
export const sellOrderSelector = createSelector(sellOrder, order => order);

const balanceViewAssetTab = state => get(state, 'balanceView.balanceViewAssetTab', [{ name: "ETH", current: true }, { name: "NER", current: false }]);
export const balanceViewAssetTabSelector = createSelector(balanceViewAssetTab, tabs => tabs);

const balanceViewAssetActionTab = state => get(state, 'balanceView.balanceViewAssetActionTab', [{ name: "Deposit", current: true }, { name: "Withdraw", current: false }]);
export const balanceViewAssetActionTabSelector = createSelector(balanceViewAssetActionTab, assetActionTabs => assetActionTabs );

const newOrderViewActionTab = state => get(state, 'newOrderView.newOrderViewActionTab', [{ name: 'Buy', current: true }, { name: 'Sell', current: false }]);
export const newOrderViewActionTabSelector = createSelector(newOrderViewActionTab, actionTabs => actionTabs);