import React, { Component } from "react";
import { connect } from "react-redux";
import { accountSelector } from "../store/selectors";
import nerdyLogo from "../assets/logo.png";
import metaMaskLogo from "../assets/metamask_icon.png";
import "./css/Navbar.css";

class Navbar extends Component {

    getShortAccountNumber = (accountNumber) => {
        const _LastNumbers = 5;

        if(accountNumber != null && accountNumber.length > 11) {
            var start = accountNumber.substring(0, 6)
            var end = accountNumber.substring(accountNumber.length - _LastNumbers);
            return start + "..." + end;
        }
        return "";
    }

    render() {
        return(
            <nav className="navbar navbar-expand-lg navbar-dark purpleBackground">

                <div className="logoWrapper mx-2">
                    <img className="logo" src={nerdyLogo} alt="Nerdy logo"/>
                </div>

                <a className="navbar-brand" href="/#">NERDY</a>
                
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ml-auto">
                        
                        <li className="nav-item"
                        >
                            <a className="flex flex-row transperantOpacityBackground rounded-md px-2" href={`https://etherscan.io/address/${this.props.account}`}>
                                <img className="logo" src={metaMaskLogo} alt="Metamask wallet logo"/>
                                <span className="nav-link text-white small">
                                    { this.getShortAccountNumber(this.props.account) }
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                </div>

                <div className="purpleTextMasterWrapper">
                    <div className="purpleTextWrapper mx-2">
                        <label className="text-white mx-4 my-2">Trade</label>
                    </div>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        account: accountSelector(state)
    }
}

export default connect(mapStateToProps)(Navbar)