import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    filledOrdersLoadedSelector,
    filledOrdersSelector
} from '../store/selectors';
import Spinner from './Spinner';
import "./css/Trades.css";

const showFilledOrders = (filledOrders) => {
    return(
        <tbody className="noBorder">
            { 
                filledOrders.map((order) => {
                    return (
                        <tr className={`order-${order.id}`} key={order.id}>
                            <td className="text-muted noBorder">
                                { order.formattedTimestamp }
                            </td>
                            <td className="noBorder">
                                { order.tokenAmount }
                            </td>
                            <td className={`text-${order.tokenPriceClass} noBorder`}>
                                { order.tokenPrice }
                            </td>
                        </tr>
                    );
                })
           }
        </tbody>
    );
}


class Trades extends Component { 
    render() {
        return(
            <div className="vertical">
                <div className="card tradesCard text-white">
                    <div className="card-header">
                        Trade History
                    </div>
                    <div className="card-body">
                    <table className="table text-white table-sm small transparentTable">
                        <thead>
                            <tr>
                                <th className="noBorder">TIME</th>
                                <th className="noBorder">NER</th>
                                <th className="noBorder">ETH/NER</th>
                            </tr>
                        </thead>
                        { this.props.filledOrdersLoaded ? showFilledOrders(this.props.filledOrders) : <Spinner type="table" /> }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        filledOrdersLoaded: filledOrdersLoadedSelector(state),
        filledOrders: filledOrdersSelector(state),
    }
}

export default connect(mapStateToProps)(Trades);